import { FC, useEffect, useState } from 'react';
import GachaStickyBanner from '@features/campaigns/gacha-2025-03-06/components/GachaStickyBanner';
import { useIsGacha20250306CampaignActive } from '@hooks/useCampaign';
import { useNeighbor } from '@hooks/useNeighbor';

type Props = {
  withBottomNav: boolean;
};

const showStickyBannerLocalStorageKey = 'showGachaCampaignStickyBanner20250306';

const GachaStickyBannerContainer: FC<Props> = ({ withBottomNav }) => {
  const [showBanner, setShowBanner] = useState(false);
  const { isFreePlan, isLoggedIn } = useNeighbor();
  const isGacha20250306CampaignActive = useIsGacha20250306CampaignActive();

  const onClickClose = () => {
    localStorage.setItem(showStickyBannerLocalStorageKey, 'false');
    setShowBanner(false);
  };

  useEffect(() => {
    const shouldShowBanner =
      localStorage.getItem(showStickyBannerLocalStorageKey) !== 'false';
    setShowBanner(shouldShowBanner);
  }, []);

  if (
    !isGacha20250306CampaignActive ||
    !showBanner ||
    isFreePlan ||
    !isLoggedIn
  ) {
    return null;
  }

  return (
    <GachaStickyBanner
      withBottomNav={withBottomNav}
      onClickClose={onClickClose}
    />
  );
};

export default GachaStickyBannerContainer;
