import { FC } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import IconCloseCircleMonochrome from '@svg-icons/IconCloseCircleMonochrome';
import routes from '@utils/routes';
import styles from './css';

type Props = {
  onClickClose: () => void;
  withBottomNav: boolean;
};

const GachaStickyBanner: FC<Props> = ({ onClickClose, withBottomNav }) => {
  return (
    <div
      className={classNames('sticky-banner', {
        'with-bottom-nav': withBottomNav,
      })}
    >
      <Link href={routes.invite}>
        <Desktop>
          <Image
            alt="空旅ガチャアップデート記念！招待キャンペーン 初月30%OFF+空旅ガチャ3階無料"
            height={175}
            src="/images/campaign/gacha-202503/sticky-banner-pc.png?v=2"
            width={175}
          />
        </Desktop>
        <Mobile>
          <Image
            alt="空旅ガチャアップデート記念！招待キャンペーン 初月30%OFF+空旅ガチャ3階無料"
            height={60}
            src="/images/campaign/gacha-202503/sticky-banner-sp.png?v=2"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={375}
          />
        </Mobile>
      </Link>
      <button className="close-button" type="button" onClick={onClickClose}>
        <Desktop>
          <IconCloseCircleMonochrome />
        </Desktop>
        <Mobile>
          <IconCloseCircleMonochrome size="sm" />
        </Mobile>
      </button>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default GachaStickyBanner;
