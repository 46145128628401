import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .sticky-banner {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 8;
    width: 100%;
    @media (min-width: ${BREAKPOINT.desktop}px) {
      bottom: 24px;
      right: 32px;
      width: initial;
      background: initial;
    }
    &.with-bottom-nav {
      bottom: 64px;
      @media (min-width: ${BREAKPOINT.desktop}px) {
        bottom: 16px;
      }
    }
    .close-button {
      position: absolute;
      top: -10px;
      right: 8px;
      @media (min-width: ${BREAKPOINT.mobile}px) {
        top: 0px;
        right: 20px;
      }
      display: inline-block;
      font-size: 0;
      line-height: 0;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;

export default styles;
