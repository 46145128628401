import { useAppSelector } from '@hooks/useAppSelector';
import { GLOBAL_REFERRAL_CAMPAIGN_CODE } from '@utils/campaign';

export const useReferralCampaign = () => {
  const { referralCampaign } = useAppSelector((state) => ({
    referralCampaign: state.referralCampaign,
  }));

  const referralCampaignCode = referralCampaign?.code;

  const isGlbReferralCampaignActive =
    referralCampaignCode === GLOBAL_REFERRAL_CAMPAIGN_CODE;

  const isTwReferralCampaignActive =
    referralCampaignCode === '20250313-tw-standard';

  return {
    isGlbReferralCampaignActive,
    isTwReferralCampaignActive,
    referralCampaign,
    referralCampaignCode,
  };
};
