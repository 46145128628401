import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import IconCloseCircleMonochrome from '@svg-icons/IconCloseCircleMonochrome';
import routes from '@utils/routes';
import styles from './css';

type Props = {
  withBottomNav: boolean;
};

const showStickyBannerLocalStorageKey = 'showTw666CampaignStickyBanner';

const Tw666StickyBanner: FC<Props> = ({ withBottomNav }) => {
  const [showBanner, setShowBanner] = useState(false);

  const onClickClose = () => {
    localStorage.setItem(showStickyBannerLocalStorageKey, 'false');
    setShowBanner(false);
  };

  useEffect(() => {
    const shouldShowBanner =
      localStorage.getItem(showStickyBannerLocalStorageKey) !== 'false';
    setShowBanner(shouldShowBanner);
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className={classNames('sticky-banner', {
        'with-bottom-nav': withBottomNav,
      })}
    >
      <Link href={routes.invite}>
        <Desktop>
          <Image
            alt="tw-666-sticky-banner"
            height={175}
            src="/images/campaign/tw-666/sticky-banner-desktop.png"
            width={175}
          />
        </Desktop>
        <Mobile>
          <Image
            alt="tw-666-sticky-banner"
            height={60}
            src="/images/campaign/tw-666/sticky-banner-mobile.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={375}
          />
        </Mobile>
      </Link>
      <button className="close-button" type="button" onClick={onClickClose}>
        <Desktop>
          <IconCloseCircleMonochrome />
        </Desktop>
        <Mobile>
          <IconCloseCircleMonochrome size="sm" />
        </Mobile>
      </button>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Tw666StickyBanner;
