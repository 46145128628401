import IconCloseCircleMonochromeSVG from '@assets/icon-close-circle-monochrome.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  size?: 'md' | 'sm';
};

const IconCloseCircleMonochrome = ({ size = 'md' }: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <div>
      <IconCloseCircleMonochromeSVG style={style} />

      <style jsx>{styles}</style>
    </div>
  );
};

export default IconCloseCircleMonochrome;
