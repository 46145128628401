import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import GachaStickyBannerContainer from '@features/campaigns/gacha-2025-03-06/containers/GachaStickyBannerContainer';
import Tw666StickyBanner from '@features/campaigns/tw-666/components/Tw666StickyBanner';
import { useAppSelector } from '@hooks/useAppSelector';
import { useIsGacha20250306CampaignActive } from '@hooks/useCampaign';
import { useReferralCampaign } from '@hooks/useInvitation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import * as referralCampaignActions from '@redux/modules/referralCampaign';
import { useActions } from '@utils/hooks';
import routes from '@utils/routes';
import { isEmpty } from '@utils/utils';

const SignUpCoinPackStickyBanner = dynamic(
  () => import('@molecules/SignUpCoinPackStickyBanner')
);
const CoinPackCampaignStickyBanner = dynamic(
  () => import('@molecules/CoinPackCampaignStickyBanner')
);
const ReferralStickyBanner = dynamic(
  () => import('@molecules/ReferralStickyBanner')
);

const StickyBanners = ({ withBottomNav }: { withBottomNav: boolean }) => {
  const { authUser, loadingAuthUser, neighbor } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
    neighbor: state.neighbor,
  }));
  const { getReferralCampaign } = useActions({ ...referralCampaignActions });
  const { locale, pathname } = useRouter();
  const isGacha20250306CampaignActive = useIsGacha20250306CampaignActive();
  const { isRegistrationCountryTaiwan } = useRegistrationCountry();
  const { isTwReferralCampaignActive } = useReferralCampaign();

  const stickyBannerRenderRoutes = [
    routes.profile,
    routes.inbox,
    routes.favoriteProperties,
    routes.reservations,
    routes.topics,
    routes.topic,
    routes.about,
    routes.hafhPlan,
    routes.coin,
    routes.root,
  ];

  const shouldRenderStickyBanners = stickyBannerRenderRoutes.includes(pathname);

  /* remove comment to enable referral campaign */
  useEffect(() => {
    if (shouldRenderStickyBanners && !loadingAuthUser) {
      getReferralCampaign(authUser, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, loadingAuthUser, locale, shouldRenderStickyBanners]);

  if (isEmpty(neighbor) || !shouldRenderStickyBanners) {
    return null;
  }

  if (isRegistrationCountryTaiwan && isTwReferralCampaignActive) {
    return <Tw666StickyBanner withBottomNav={withBottomNav} />;
  }

  if (isGacha20250306CampaignActive) {
    return <GachaStickyBannerContainer withBottomNav={withBottomNav} />;
  }

  return (
    <>
      <ReferralStickyBanner withBottomNav={withBottomNav} />
      <SignUpCoinPackStickyBanner withBottomNav={withBottomNav} />
      <CoinPackCampaignStickyBanner withBottomNav={withBottomNav} />
    </>
  );
};

export default StickyBanners;
